import { Box, LinearProgress } from '@mui/material';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from 'react';

// ----------------------------------------------------------------------

type ContextLoaderTop_t = {
  setLoaderTop: Dispatch<SetStateAction<boolean>>;
};

export const ContextLoaderTop = createContext<ContextLoaderTop_t>({
  setLoaderTop: () => null,
});

const LoaderTop: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loaderTop, setLoaderTop] = useState(false);

  return (
    <ContextLoaderTop.Provider
      value={{
        setLoaderTop: setLoaderTop,
      }}
    >
      <Box sx={{ width: '100%', position: 'absolute', zIndex: 11000 }}>
        {loaderTop && <LinearProgress sx={{ height: 3 }} />}
      </Box>
      {children}
    </ContextLoaderTop.Provider>
  );
};

// ----------------------------------------------------------------------

export default LoaderTop;
