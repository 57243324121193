import { CheckRounded, Notifications } from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListSubheader,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { transparentize } from 'polished';
import { Notification_t } from 'ts/types/notification.type';
import UseNotification from './use-notification';
import LoadingBox from '../loaders/loading-box';
import { io, Socket } from 'socket.io-client';
import { API_URL } from 'libs/env';
import Cookies from 'js-cookie';
import { useInView } from 'react-intersection-observer';

const COUNT_NOTIFICATIONS_FETCH = 10;

export default function NotificationsPopover() {
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<
    Notification_t[] | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const useNotification = UseNotification();
  const [isNoMoreNotification, setIsNoMoreNotification] =
    useState<boolean>(false);

  const getMoreNotifications = async () => {
    if (isNoMoreNotification) return;
    try {
      const start = notifications ? notifications.length : 0;
      const end = start + COUNT_NOTIFICATIONS_FETCH - 1;
      const moreNotifications = await useNotification.getNotifications({
        start: start,
        end: end,
      });
      if (Number(moreNotifications?.length) < COUNT_NOTIFICATIONS_FETCH)
        setIsNoMoreNotification(true);
      const newNotifications = (notifications ? notifications : []).concat(
        moreNotifications ? moreNotifications : [],
      );
      setNotifications(newNotifications);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getMoreNotifications();
      setLoading(false);
    })();
  }, []);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  // Notification socket handle
  useEffect(() => {
    const socket: Socket = io(API_URL, {
      auth: {
        token: Cookies.get('token'),
      },
    });

    socket.on('newNotification', (notification: Notification_t) => {
      setNotifications((prevNotifications) => [
        notification,
        ...(prevNotifications ? prevNotifications : []),
      ]);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton sx={{ ml: 1.5 }} onClick={handleOpen}>
          <Badge
            badgeContent={
              notifications
                ? notifications?.filter(
                    (notification) => !notification.viewedOn,
                  ).length
                : 0
            }
            color="secondary"
          >
            <Notifications
              sx={{
                color: (theme) => theme.palette.grey[300],
                fontSize: '0.9em',
              }}
            />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '& .MuiPopover-paper': {
            width: '500px',
            bgcolor: (theme) =>
              transparentize(0.15, theme.palette.background.paper),
            backdropFilter: 'blur(10px)',
          },
        }}
      >
        {loading && (
          <Box py={4}>
            <LoadingBox size="small" />
          </Box>
        )}
        {!loading &&
          notifications &&
          (1 <= notifications.length ? (
            <Fill
              handleClose={handleClose}
              notifications={notifications}
              setNotifications={setNotifications}
              getMoreNotifications={getMoreNotifications}
              isNoMoreNotification={isNoMoreNotification}
            />
          ) : (
            <Empty />
          ))}
      </Popover>
    </>
  );
}

type Props_Fill = {
  handleClose: () => void;
  notifications: Notification_t[];
  setNotifications: React.Dispatch<
    React.SetStateAction<Notification_t[] | undefined>
  >;
  getMoreNotifications: () => Promise<void>;
  isNoMoreNotification: boolean;
};

function Fill({
  handleClose,
  notifications,
  setNotifications,
  getMoreNotifications,
  isNoMoreNotification,
}: Props_Fill) {
  const useNotification = UseNotification();
  const newNotifications = notifications
    ? notifications?.filter((notification) => !notification.viewedOn)
    : [];
  const viewedNotifications = notifications
    ? notifications?.filter((notification) => notification.viewedOn)
    : [];

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0,
  });

  const handleClick_setAllViewed = async () => {
    const updatedNotifications = await useNotification.setNotificationViewed({
      notifications: notifications,
    });
    setNotifications(updatedNotifications);
  };

  useEffect(() => {
    (async () => {
      if (inView) await getMoreNotifications();
    })();
  }, [inView, getMoreNotifications]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">Notifications</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {1 <= newNotifications?.length
              ? `Vous avez ${newNotifications?.length} nouvelle${2 <= newNotifications?.length ? 's' : ''} notification${2 <= newNotifications?.length ? 's' : ''}`
              : 'Vous avez aucune nouvelle notification'}
          </Typography>
        </Box>

        {1 <= newNotifications.length && (
          <Tooltip title="Tout marquer comme lu">
            <IconButton color="inherit" onClick={handleClick_setAllViewed}>
              <CheckRounded />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Box maxHeight="500px" sx={{ overflowY: 'scroll' }}>
        {1 <= newNotifications.length && (
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: 'overline' }}
              >
                Nouveau
              </ListSubheader>
            }
          >
            {newNotifications.map((notification, key) => (
              <NotificationItem
                notifications={notifications}
                key={key}
                notification={notification}
                setNotifications={setNotifications}
                handleClose={handleClose}
              />
            ))}
          </List>
        )}

        {1 <= viewedNotifications.length && (
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: 'overline' }}
              >
                Déjà lu
              </ListSubheader>
            }
          >
            {viewedNotifications.map((notification, key) => (
              <NotificationItem
                key={key}
                notifications={notifications}
                notification={notification}
                setNotifications={setNotifications}
                handleClose={handleClose}
              />
            ))}
          </List>
        )}

        {!isNoMoreNotification && (
          <Box py={5} ref={ref}>
            <LoadingBox size="small" />
          </Box>
        )}
      </Box>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Box sx={{ p: 1 }}>
        <Button
          fullWidth
          disableRipple
          color="inherit"
          disabled={newNotifications.length === 0}
          onClick={handleClick_setAllViewed}
        >
          Marquer toutes les notifications comme lues
        </Button>
      </Box>
    </>
  );
}

function Empty() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle1">Notifications</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Vous avez aucune notification
        </Typography>
      </Box>
    </Box>
  );
}

type Props_NotificationItem = {
  setNotifications: React.Dispatch<
    React.SetStateAction<Notification_t[] | undefined>
  >;
  notifications: Notification_t[];
  notification: Notification_t;
  handleClose: () => void;
};

function NotificationItem({
  setNotifications,
  notifications,
  notification,
  handleClose,
}: Props_NotificationItem) {
  const useNotification = UseNotification();
  const NotificationType = useNotification.getNotificationType(
    notification.entityType,
  );
  return (
    <>
      {NotificationType && (
        <NotificationType
          setNotifications={setNotifications}
          notifications={notifications}
          notification={notification}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
