// import { isProdEnv } from 'libs/env';
import { isProdEnv } from 'libs/env';
import Promotion from './common/promotion';

type Props_GlobalLayout = {
  children: React.ReactNode;
};

export default function GlobalLayout({ children }: Props_GlobalLayout) {
  return (
    <>
      {isProdEnv() && true && <Promotion />}
      {children}
    </>
  );
}
