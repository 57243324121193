import { Typography } from '@mui/material';

type Props_MatterText = {
  text: string;
};

export default function MatterText({ text }: Props_MatterText) {
  return (
    <Typography
      component="span"
      sx={{
        fontWeight: 600,
        color: (theme) => theme.palette.grey[300],
        fontSize: 'inherit',
      }}
    >
      {text}
    </Typography>
  );
}
