import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { Data_newMedia_t } from 'ts/types/notification.type';
import useNotification, { Props_NotificationItem } from '../use-notification';
import useDate from 'hooks/use-date';
import MatterText from './common/matter-text';
import PrimaryContainer from './common/primary-container';

export default function NotifNewMedia({
  notification,
}: Props_NotificationItem) {
  const data = notification.data as Data_newMedia_t;
  const theme = useTheme();
  const notificationHook = useNotification();
  const date = useDate();

  return (
    <ListItem
      sx={{
        ...notificationHook.style.styleListItem({
          viewed: Boolean(notification.viewedOn),
        }),
      }}
    >
      <ListItemAvatar sx={{ height: '100%', mt: 0.5 }}>
        <Avatar
          alt={`${data.firstName} ${data.lastName}`}
          src="/"
          sx={{
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.grey[300],
            bgcolor: theme.palette.grey[600],
          }}
        ></Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <PrimaryContainer createdAt={notification.createdAt}>
            <Typography variant="body2" color={theme.palette.grey[400]}>
              <MatterText text={`${data.firstName}`} /> a rempli le jour{' '}
              {date.getTimeDiff({
                startDate: data.startDate,
                endDate: data.date,
              }) + 1}{' '}
              de <MatterText text={`${data.title}`} />
            </Typography>
          </PrimaryContainer>
        }
      />
    </ListItem>
  );
}
