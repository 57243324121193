import Asset from 'components/assets/asset';

// ----------------------------------------------------------------------

type Props_Logo = {
  short?: boolean;
  widthPx: number;
};

const Logo: React.FC<Props_Logo> = ({ short, widthPx }: Props_Logo) => {
  return (
    <a href="/" style={{ cursor: 'pointer' }}>
      {short ? (
        <Asset pathname="/logo/trsform-short.png" widthPx={widthPx} />
      ) : (
        <Asset pathname="/logo/trsform.png" widthPx={widthPx} />
      )}
    </a>
  );
};

export default Logo;
