import Cookies from 'js-cookie';
import { API_URL } from 'libs/env';

export class Response {
  status: 'success' | 'error';
  codeHTTP: number;
  code: string | null; // null if success
  message: string | null; // null if success
  data: any | null; // null if error

  constructor({
    status,
    codeHTTP,
    message,
    data,
    code,
  }: {
    status: 'success' | 'error';
    codeHTTP: number;
    message: string | null;
    data: any | null;
    code: string | null;
  }) {
    this.status = status;
    this.codeHTTP = codeHTTP;
    this.message = message;
    this.data = data;
    this.code = code;
  }

  isSuccess(): boolean {
    return this.status === 'success';
  }
}

type Method = 'POST' | 'GET' | 'PUT' | 'DELETE';
type RequestObject = {
  url: string;
  method: Method;
  body?: any;
  isFile?: boolean;
  isToken?: boolean;
};

type RequestObject_getFile = {
  type: 'image';
  fileId: number;
  token?: string;
  params?: { [key: string]: any };
};

export class Request {
  static getHeaders(isToken: boolean, isFile: boolean): HeadersInit {
    const headers: Record<string, string> = {};
    if (isToken) headers['Authorization'] = `Bearer ${Cookies.get('token')}`;
    if (!isFile) headers['Content-Type'] = 'application/json';
    return headers;
  }

  static async send({
    url,
    method,
    body = {},
    isFile = false,
    isToken = true,
  }: RequestObject) {
    let status: 'success' | 'error';
    let codeHTTP: number;
    let code: string | null = null;
    let message: string | null = null;
    let data: any | null = null;

    try {
      const requestInit: RequestInit = {
        method: method,
        headers: Request.getHeaders(isToken, isFile),
      };

      if (method !== 'GET') {
        requestInit.body = isFile ? body : JSON.stringify(body);
      }

      const response = await fetch(`${API_URL}/${url}`, requestInit);

      codeHTTP = response.status;

      const res = await response.json();
      status = res.status;

      if (res.status === 'success') {
        data = res.data;
      } else if (res.status === 'error') {
        code = res.code;
        message = res.message;
      }

      return new Response({
        status,
        codeHTTP,
        message,
        data,
        code,
      });
    } catch (error: any) {
      throw new Error(
        `Une erreur s'est produite lors de la requête GET vers ${API_URL}/${url}: ${error.message}`,
      );
    }
  }

  static async getFile({
    type,
    fileId,
    token,
    params,
  }: RequestObject_getFile): Promise<string> {
    const ParsedParams = new URLSearchParams(params);

    const response = await fetch(
      `${API_URL}/file/${type}/${fileId}${token ? `/${token}` : ''}?${ParsedParams.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();
    const fileUrl = URL.createObjectURL(blob);
    return fileUrl;
  }
}
