import React from 'react';
import { motion } from 'framer-motion';

type Props_Asset = {
  pathname: string;
  widthPx?: number;
  alt?: string;
  style?: React.CSSProperties;
};

const Asset = React.forwardRef<HTMLImageElement, Props_Asset>(
  ({ pathname, widthPx, alt = '', style }, ref) => {
    return (
      <img
        ref={ref}
        src={'/assets' + pathname}
        alt={alt}
        style={{
          width: widthPx ? `${widthPx}px` : undefined,
          ...style,
        }}
      />
    );
  },
);

Asset.displayName = 'Asset';

export default motion(Asset);
