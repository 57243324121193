import { ReactNode, useMemo } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  Theme,
  ThemeOptions,
} from '@mui/material/styles';

import { palette } from './palette';
import { shadows } from './shadows';
import { overrides } from './overrides';
import { typography } from './typography';
import { ThemeProviderProps } from '@mui/material/styles/ThemeProvider';

// ----------------------------------------------------------------------

export default function ThemeProvider({
  children,
}: {
  children: ReactNode;
}): React.ReactElement<ThemeProviderProps<Theme>> {
  const mode = 'dark';

  const memoizedValue: ThemeOptions = useMemo(
    () => ({
      mode: mode,
      palette: palette(mode),
      typography,
      shadows: shadows(mode),
      shape: { borderRadius: 8 },
    }),
    [],
  );

  const theme: Theme = createTheme(memoizedValue);

  theme.components = overrides(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}
