import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Copyright from 'components/brands/copyright';
import Logo from 'components/brands/logo';

// ----------------------------------------------------------------------

type Props_AuthentificationLayout = {
  children: React.ReactNode;
};

export default function AuthentificationLayout({
  children,
}: Props_AuthentificationLayout) {
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          px: 4,
          py: 3,
        }}
      >
        <Logo widthPx={120} />
      </Box>
      <Container maxWidth="xs">
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {children}
          <Copyright
            sx={{
              mt: 4,
            }}
          />
        </Box>
      </Container>
    </>
  );
}
