import { Typography, useTheme } from '@mui/material';
import useDate from 'hooks/use-date';

type Props_MatterText = {
  children: React.ReactNode;
  createdAt: string;
};

export default function PrimaryContainer({
  children,
  createdAt,
}: Props_MatterText) {
  const theme = useTheme();
  const date = useDate();
  return (
    <>
      {children}
      <Typography
        variant="caption"
        color={theme.palette.grey[600]}
        sx={{ mt: 0.5 }}
      >
        Il y a {date.formatTimeSince(createdAt)}
      </Typography>
    </>
  );
}
