import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { Data_createShare_t } from 'ts/types/notification.type';
import { Request } from 'api/request';
import { useSnackbar } from 'hooks/use-snackbar';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Status } from 'ts/enum/status.enum';
import MatterText from './common/matter-text';
import PrimaryContainer from './common/primary-container';
import useNotification, { Props_NotificationItem } from '../use-notification';

export default function NotifCreateShare({
  setNotifications,
  notification,
  notifications,
}: Props_NotificationItem) {
  const data = notification.data as Data_createShare_t;
  const theme = useTheme();
  const notificationHook = useNotification();

  const StateShare = () => {
    const snackbar = useSnackbar();
    const [loadingAccept, setLoadingAccept] = useState(false);
    const [loadingRefuse, setLoadingRefuse] = useState(false);
    const handleClick = (type: 'accept' | 'refuse') => async () => {
      const isAccepted = type === 'accept';
      const setLoading = isAccepted ? setLoadingAccept : setLoadingRefuse;
      setLoading(true);
      try {
        const body = {
          share: {
            isAccepted: isAccepted,
          },
        };
        const response = await Request.send({
          url: `share/response/${data.shareId}`,
          method: 'PUT',
          body: body,
        });
        if (response.isSuccess()) {
          const index = notifications?.findIndex(
            (notification_) =>
              notification_.notificationId === notification.notificationId,
          );
          let updatedNotifications = notifications.slice(0);
          updatedNotifications[index] = {
            ...updatedNotifications[index],
            data: {
              ...updatedNotifications[index].data,
              isAccepted: isAccepted,
            },
          };
          updatedNotifications = await notificationHook.setNotificationViewed({
            notifications: updatedNotifications,
            notificationId: notification.notificationId,
          });
          setNotifications(updatedNotifications);
        } else {
          snackbar.popError({});
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    if (data.isAccepted === null)
      return (
        <>
          <LoadingButton
            onClick={handleClick('refuse')}
            // size="small"
            variant="outlined"
            color="inherit"
            sx={{ borderColor: theme.palette.grey[700] }}
            disabled={loadingAccept || loadingRefuse}
            loading={loadingRefuse}
          >
            Refuser
          </LoadingButton>
          <LoadingButton
            onClick={handleClick('accept')}
            // size="small"
            variant="contained"
            disableElevation
            color="secondary"
            loading={loadingAccept}
            disabled={loadingAccept || loadingRefuse}
          >
            Accepter
          </LoadingButton>
        </>
      );
    if (data.isAccepted === true)
      return (
        <Typography
          variant="subtitle2"
          sx={{ color: theme.palette.success.light }}
        >
          Vous avez accepté la demande de partage
        </Typography>
      );
    if (data.isAccepted === false)
      return (
        <Typography
          variant="subtitle2"
          sx={{ color: theme.palette.error.light }}
        >
          Vous avez refusé la demande de partage
        </Typography>
      );
    return <></>;
  };

  return (
    <ListItem
      sx={{
        ...notificationHook.style.styleListItem({
          viewed: Boolean(notification.viewedOn),
        }),
      }}
    >
      <ListItemAvatar sx={{ height: '100%', mt: 0.5 }}>
        <Avatar
          alt={`${data.firstName} ${data.lastName}`}
          src="/"
          sx={{
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.grey[300],
            bgcolor: theme.palette.grey[600],
          }}
        ></Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <PrimaryContainer createdAt={notification.createdAt}>
              <Typography variant="body2" color={theme.palette.grey[400]}>
                <MatterText text={`${data.firstName} ${data.lastName}`} /> vous
                a envoyé une invitation de partage à{' '}
                <MatterText text={`${data.title}`} />
              </Typography>
            </PrimaryContainer>
            {data.status === Status.ACTIVE && (
              <Box display="flex" gap={1.5} mt={2}>
                <StateShare />
              </Box>
            )}
          </>
        }
      />
    </ListItem>
  );
}
