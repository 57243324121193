import { isProdEnv } from 'libs/env';
import { GTM_ID } from 'libs/gtm';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

// ----------------------------------------------------------------------

export function useGtm() {
  // GTM Initialization
  useEffect(() => {
    if (isProdEnv())
      TagManager.initialize({
        gtmId: GTM_ID,
      });
  }, []);

  return null;
}
