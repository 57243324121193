/**
 * @file NavBar.tsx
 * @brief
 */

/* -----------------------------------------------------------------------------
* Import
-------------------------------------------------------------------------- */
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  DashboardRounded,
  ImageRounded,
  PersonRounded,
  ShoppingBagRounded,
} from '@mui/icons-material';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { lighten } from 'polished';
import Logo from 'components/brands/logo';
import { useLoaderTop } from 'components/loaders/hooks/use-loader-top';

/* -----------------------------------------------------------------------------
  * AppBar
  -------------------------------------------------------------------------- */
const tabList = [
  {
    path: '',
    label: 'DASHBOARD',
    icon: <DashboardRounded fontSize="small" />,
  },
  {
    path: 'gallery/my-trsforms',
    label: 'MA GALERIE',
    icon: <ImageRounded fontSize="small" />,
  },
  {
    path: 'shop',
    label: 'BOUTIQUE',
    icon: <ShoppingBagRounded fontSize="small" />,
  },
  {
    path: 'profile/informations',
    label: 'PROFIL',
    icon: <PersonRounded fontSize="small" />,
  },
];

const locList = ['', 'gallery', 'shop', 'profile'];

const getIndex: (location: Location<any>) => number = (
  location: Location<any>,
) => {
  const pathnameSplit: string[] = location.pathname.split('/');
  const lastPath: string = pathnameSplit[2] ? pathnameSplit[2] : '';
  return locList.findIndex((loc) => loc === lastPath);
};

export default function NavBar() {
  const loaderTop = useLoaderTop();

  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = React.useState(getIndex(location));

  React.useEffect(() => {
    setValue(getIndex(location));
  }, [location]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      component="nav"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 3,
        borderRight: 0.5,
        borderColor: (theme) => lighten(0.15, theme.palette.background.default),
        bgcolor: (theme) => lighten(0.02, theme.palette.background.default),
      }}
    >
      <Logo short widthPx={45} />
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        sx={{
          mt: 9,
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
        textColor="inherit"
      >
        {tabList.map((tab, index) => (
          <Tab
            key={index}
            onClick={() => {
              loaderTop.stop();
              navigate(tab.path);
            }}
            sx={{
              fontSize: '0.56em',
              fontWeight: 600,
            }}
            icon={tab.icon}
            label={tab.label}
          />
        ))}
      </Tabs>
    </Box>
  );
}
