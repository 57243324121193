import { useContext } from 'react';
import { ContextLoaderTop } from 'components/loaders/loader-top';

// ----------------------------------------------------------------------

export function useLoaderTop() {
  const { setLoaderTop } = useContext(ContextLoaderTop);

  const loaderTop = {
    loading: () => setLoaderTop(true),
    stop: () => setLoaderTop(false),
  };

  return loaderTop;
}
