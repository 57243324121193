export enum EntityTypes {
  // Share notification (100-199)
  createShare = 100,
  responseShare = 101,
  deleteShare = 102,
  newMedia = 103,

  // Achievement notification (200-299)
  newAchievement = 200,
}
