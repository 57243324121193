import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Button, SvgIconTypeMap } from '@mui/material';
import {
  DashboardRounded,
  ImageRounded,
  KeyboardArrowRight,
  PersonRounded,
  ShoppingBagRounded,
} from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useRouter } from 'routes/hooks/use-router';

// ----------------------------------------------------------------------

type Link_t = {
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  label: string;
  pathName: string;
  key: number;
};

export default function Path() {
  const location = useLocation();
  const router = useRouter();

  const [splittedPathname, setSplittedPathname] = React.useState<string[]>([]);

  React.useEffect(() => {
    setSplittedPathname(location.pathname.split('/').slice(1));
  }, [location.pathname]);

  const Link: (link: Link_t) => React.ReactNode = (link) => {
    return (
      <Button
        sx={{
          fontWeight: 600,
          fontSize: '0.8em',
          // color: (theme) => theme.palette.grey[500],
          cursor: 'pointer',
        }}
        color="inherit"
        onClick={() => router.push(link.pathName)}
        key={link.key}
      >
        {link.Icon && <link.Icon sx={{ mr: 1, fontSize: '1.4em' }} />}
        {link.label}
      </Button>
    );
  };

  // Galerie page logic
  const getLinkList_gallery: (
    splittedPathname: string[],
    key: number,
  ) => React.ReactNode[] = (splittedPathname: string[], key: number) => {
    const linkList: React.ReactNode[] = [];
    const firstSegment: string = splittedPathname[1];

    switch (firstSegment) {
      case 'trsform':
        const title: string = decodeURIComponent(splittedPathname[4]);
        const trsformId: string = splittedPathname[3];
        const type: string = splittedPathname[2];

        linkList.push(
          Link({
            label: title as string,
            pathName: `/user/gallery/trsform/${type}/${trsformId}/${title}`,
            key,
          }),
        );
        break;

      default:
        break;
    }

    return linkList;
  };

  // User page logic
  // const getLinkList_user: (
  //   splittedPathname: string[],
  //   key: number,
  // ) => React.ReactNode[] = (splittedPathname: string[], key: number) => {
  //   let linkList: React.ReactNode[] = [];
  //   const firstSegment: string = splittedPathname[0];
  //   // const remainingSegments: string[] = splittedPathname.slice(1);

  //   switch (firstSegment) {
  //     // Informations view
  //     case 'information':
  //       linkList.push(
  //         Link({
  //           Icon: AccountBoxRounded,
  //           label: 'Informations personnelles',
  //           pathName: 'user/information',
  //           key,
  //         }),
  //       );
  //       break;

  //     // Mail adress
  //     case 'email':
  //       linkList.push(
  //         Link({
  //           Icon: EmailRounded,
  //           label: 'Adresse mail',
  //           pathName: 'user/email',
  //           key,
  //         }),
  //       );
  //       break;

  //     // Password
  //     case 'password':
  //       linkList.push(
  //         Link({
  //           Icon: LockRounded,
  //           label: 'Mot de passe',
  //           pathName: 'user/password',
  //           key,
  //         }),
  //       );
  //       break;

  //     // Invoices
  //     case 'invoice':
  //       linkList.push(
  //         Link({
  //           Icon: ReceiptRounded,
  //           label: 'Factures',
  //           pathName: 'user/invoice',
  //           key,
  //         }),
  //       );
  //       break;
  //     default:
  //       break;
  //   }

  //   return linkList;
  // };

  // Global logic
  const getLinkList: (
    splittedPathname: string[],
    key: number,
  ) => React.ReactNode[] = (splittedPathname: string[], key: number) => {
    let linkList: React.ReactNode[] = [];
    const firstSegment: string = splittedPathname[1] ? splittedPathname[1] : '';
    const remainingSegments: string[] = splittedPathname.slice(1);

    switch (firstSegment) {
      // Dashboard
      case '':
        linkList.push(
          Link({
            Icon: DashboardRounded,
            label: 'Dashboard',
            pathName: '',
            key,
          }),
        );
        break;

      // gallery
      case 'gallery':
        linkList.push(
          Link({
            Icon: ImageRounded,
            label: 'Ma galerie',
            pathName: 'gallery/my-trsforms',
            key,
          }),
        );
        linkList = linkList.concat(
          getLinkList_gallery(remainingSegments, key + 1),
        );
        break;

      // Shop
      case 'shop':
        linkList.push(
          Link({
            Icon: ShoppingBagRounded,
            label: 'Boutique',
            pathName: 'shop',
            key,
          }),
        );
        break;

      // Shop
      case 'profile':
        linkList.push(
          Link({
            Icon: PersonRounded,
            label: 'Mon profil',
            pathName: 'profile',
            key,
          }),
        );
        // linkList = linkList.concat(
        //   getLinkList_user(remainingSegments, key + 1),
        // );
        break;
      default:
        break;
    }

    return linkList;
  };

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<KeyboardArrowRight fontSize="small" />}
      sx={{ fontSize: '0.95em', color: (theme) => theme.palette.grey[300] }}
    >
      {getLinkList(splittedPathname, 0)}
    </Breadcrumbs>
  );
}
