import { useContext } from 'react';
import { ContextUser } from '../context';
import { Button, Tooltip, Typography } from '@mui/material';
import { lighten } from 'polished';
import AnimatedCounter from 'components/common/animated-counter';
import { useRouter } from 'routes/hooks/use-router';

export default function Portfolio() {
  const router = useRouter();

  const { infoAccount } = useContext(ContextUser);
  const { dayCredits } = infoAccount;

  return (
    <Tooltip title="Mes jours">
      <Button
        sx={{
          border: 1,
          padding: 0.6,
          paddingLeft: 1.7,
          paddingRight: 1.7,
          borderRadius: 1,
          display: 'flex',
          alignItems: 'baseline',
          cursor: 'pointer',
          fontSize: '0.85em',
          borderColor: (theme) => theme.palette.grey[900],
          bgcolor: (theme) => lighten(0.09, theme.palette.common.black),
        }}
        color="inherit"
        onClick={() => router.push('/user/shop')}
      >
        <Typography
          sx={{
            fontWeight: 600,
            color: (theme) => theme.palette.grey[300],
            fontSize: '1.1em',
          }}
        >
          <AnimatedCounter
            count={dayCredits as number}
            timeTransitionMs={1000}
          />
        </Typography>
        <Typography
          sx={{
            color: (theme) => theme.palette.grey[600],
            fontSize: '0.8em',
            ml: 0.8,
            textTransform: 'none',
          }}
        >
          Jour{[0, 1].includes(dayCredits as number) ? '' : 's'}
        </Typography>
      </Button>
    </Tooltip>
  );
}
