import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { Data_deleteShare_t } from 'ts/types/notification.type';
import useNotification, { Props_NotificationItem } from '../use-notification';
import { ClearRounded } from '@mui/icons-material';
import MatterText from './common/matter-text';
import PrimaryContainer from './common/primary-container';

export default function NotifDeleteShare({
  notification,
}: Props_NotificationItem) {
  const data = notification.data as Data_deleteShare_t;
  const theme = useTheme();
  const notificationHook = useNotification();

  return (
    <ListItem
      sx={{
        ...notificationHook.style.styleListItem({
          viewed: Boolean(notification.viewedOn),
        }),
      }}
    >
      <ListItemAvatar
        sx={{
          height: '100%',
          mt: 0.5,
        }}
      >
        <ClearRounded color="error" fontSize="large" />
      </ListItemAvatar>
      <ListItemText
        primary={
          <PrimaryContainer createdAt={notification.createdAt}>
            <Typography variant="body2" color={theme.palette.grey[400]}>
              <MatterText text={`${data.firstName} ${data.lastName}`} /> a
              supprimé le partage sur <MatterText text={`${data.title}`} />
            </Typography>
          </PrimaryContainer>
        }
      />
    </ListItem>
  );
}
