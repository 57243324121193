import { Box } from '@mui/material';
import Footer from './footer';
import Header from './header';

type Props_GlobalLayout = {
  children: React.ReactNode;
};

export default function HomeLayout({ children }: Props_GlobalLayout) {
  return (
    <>
      <Header />
      <Box minHeight="100vh" overflow={'hidden'}>
        {children}
      </Box>
      <Footer />
    </>
  );
}
