import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

dayjs.locale('fr');

type Props_getTimeDiff = {
  startDate: string;
  endDate?: string;
  type?: 'day';
};
export default function useDate() {
  const date = {
    dayjs: dayjs,
    getTimeDiff: ({
      startDate,
      endDate = dayjs().toString(),
      type = 'day',
    }: Props_getTimeDiff) => dayjs(endDate).diff(dayjs(startDate), type),
    formatTimeSince: (date: string | Date) => dayjs(date).fromNow(true),
  };
  return date;
}
