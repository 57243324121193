import { Box, Button, Link, Stack } from '@mui/material';
import Logo from 'components/brands/logo';
import { motion } from 'framer-motion';

const CustomBox = motion(Box);

const variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
};

const links: { name: string; href: string }[] = [
  { name: 'Home', href: '/' },
  { name: 'À propos', href: '/' },
  { name: 'Contact', href: '/' },
];
export default function Header() {
  return (
    <CustomBox
      variants={variants}
      initial="hidden"
      animate="visible"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={4}
      px={5}
      position="absolute"
      top={0}
      width="100%"
    >
      <Logo widthPx={120} />
      <Box display="flex" alignItems="center">
        <Stack gap={3} direction="row">
          {links.map((link, key) => (
            <Link
              variant="body2"
              key={key}
              href={link.href}
              color="grey.300"
              underline="hover"
            >
              {link.name}
            </Link>
          ))}
        </Stack>
        <Stack gap={2} direction="row" ml={8}>
          <Button size="small" href="/auth/login">
            Se connecter
          </Button>
          <Button size="small" variant="contained" href="/auth/signup">
            S'inscrire gratuitement
          </Button>
        </Stack>
      </Box>
    </CustomBox>
  );
}
