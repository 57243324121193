import { Link, SxProps, Typography } from '@mui/material';

// ----------------------------------------------------------------------
interface CopyrightProps {
  sx?: SxProps;
}

const Copyright: React.FC<CopyrightProps> = ({ sx }: CopyrightProps) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={sx}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        TRSFORM
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default Copyright;
