import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { Data_newAchievement_t } from 'ts/types/notification.type';
import useNotification, { Props_NotificationItem } from '../use-notification';
import Asset from 'components/assets/asset';
import MatterText from './common/matter-text';
import PrimaryContainer from './common/primary-container';

export default function NotifNewAchievement({
  notification,
}: Props_NotificationItem) {
  const data = notification.data as Data_newAchievement_t;
  const theme = useTheme();
  const notificationHook = useNotification();

  return (
    <ListItem
      sx={{
        ...notificationHook.style.styleListItem({
          viewed: Boolean(notification.viewedOn),
        }),
      }}
    >
      <ListItemAvatar
        sx={{
          height: '100%',
          mt: 0.5,
        }}
      >
        <Asset pathname="/notification/party.svg" widthPx={35} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <PrimaryContainer createdAt={notification.createdAt}>
            <Typography variant="body2" color={theme.palette.grey[400]}>
              Félicitations! Vous avez débloqué un nouvel achievement dans{' '}
              <MatterText text={`${data.title}`} />. Rendez-vous maintenant pour
              récupérer votre récompense!
            </Typography>
          </PrimaryContainer>
        }
      />
    </ListItem>
  );
}
