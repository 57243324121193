import { createContext, useEffect, useState } from 'react';
import { useLoaderTop } from 'components/loaders/hooks/use-loader-top';
import { useTheme } from '@mui/material';
import { Request } from 'api/request';
import { useRouter } from 'routes/hooks/use-router';
import Cookies from 'js-cookie';
import LoadingBox from 'components/loaders/loading-box';

// ---------------------------------------------------------------------------

export type InfoUser_t = {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  urlImageProfil: string | undefined;
  isValidEmail: boolean | undefined;
};

export type InfoAccount_t = {
  dayCredits: number | undefined;
};

export type InfoGallery_t = {
  countTrsformPerso: number | undefined;
  countTrsformShare: number | undefined;
};

export type ContextUser_t = {
  infoUser: InfoUser_t;
  infoAccount: InfoAccount_t;
  infoGallery: InfoGallery_t;
  renderDashboard: () => void;
};

export const ContextUser = createContext<ContextUser_t>({
  infoUser: {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    urlImageProfil: undefined,
    isValidEmail: undefined,
  },
  infoAccount: {
    dayCredits: undefined,
  },
  infoGallery: {
    countTrsformPerso: undefined,
    countTrsformShare: undefined,
  },
  renderDashboard: () => null,
});

type Props_UserContext = {
  children: React.ReactNode;
};

export default function UserContext({ children }: Props_UserContext) {
  const router = useRouter();
  const theme = useTheme();
  const loaderTop = useLoaderTop();
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(0);

  const [contextUser, setContextUser] = useState<ContextUser_t>({
    infoUser: {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      urlImageProfil: undefined,
      isValidEmail: undefined,
    },
    infoAccount: {
      dayCredits: undefined,
    },
    infoGallery: {
      countTrsformPerso: undefined,
      countTrsformShare: undefined,
    },
    renderDashboard: () => setRender((oldRender) => oldRender + 1),
  });

  const getInfo: () => Promise<
    | {
        infoUser: InfoUser_t;
        infoAccount: InfoAccount_t;
        infoGallery: InfoGallery_t;
      }
    | undefined
  > = async () => {
    try {
      const response = await Request.send({
        url: 'user',
        method: 'GET',
      });

      if (!response.isSuccess()) {
        // To remove problem of circle redirection between login and dashboard
        Cookies.remove('token');

        loaderTop.stop();
        setLoading(false);
        router.push('/auth/login');
      }

      if (response.isSuccess()) {
        const { firstName, lastName, email, fileId_profilImage, isValidEmail } =
          response.data.user;

        const { dayCredits } = response.data.account;
        const { countTrsformPerso, countTrsformShare } = response.data.gallery;

        let urlImageProfil: string | undefined = undefined;
        if (typeof fileId_profilImage === 'number') {
          urlImageProfil = await Request.getFile({
            type: 'image',
            fileId: fileId_profilImage,
            params: {
              profilImage: true,
            },
          });
        }

        return {
          infoUser: {
            firstName,
            lastName,
            email,
            urlImageProfil,
            isValidEmail,
          },
          infoAccount: {
            dayCredits,
          },
          infoGallery: {
            countTrsformPerso,
            countTrsformShare,
          },
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Get informations of the user, redirect if token is not valid
  useEffect(() => {
    (async () => {
      loaderTop.loading();
      setLoading(true);
      try {
        const info = await getInfo();
        if (info === undefined) return;
        setContextUser({
          ...contextUser,
          infoUser: info.infoUser,
          infoAccount: info.infoAccount,
          infoGallery: info.infoGallery,
        });
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    })();
  }, [render]);

  return (
    <ContextUser.Provider value={contextUser}>
      {loading && <LoadingBox color={theme.palette.primary.main} />}

      {!loading && children}
    </ContextUser.Provider>
  );
}
