import { useEffect, useState } from 'react';
import * as TWEEN from '@tweenjs/tween.js';

type Props_AnimatedCounter = {
  count: number;
  timeTransitionMs?: number;
  isTransition?: boolean;
};
type Value = { value: number };

export default function AnimatedCounter({
  count,
  timeTransitionMs = 500,
  isTransition = true,
}: Props_AnimatedCounter) {
  const [value, setValue] = useState<number>(0);
  const valueObj: Value = { value: value };

  useEffect(() => {
    if (!isTransition) return;
    new TWEEN.Tween(valueObj)
      .to({ value: count }, timeTransitionMs)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(() => {
        setValue(Math.round(valueObj.value));
      })
      .start();
  }, [count]);

  // Setup the animation loop.
  const animate = (time: number) => {
    requestAnimationFrame(animate);
    TWEEN.update(time);
  };
  requestAnimationFrame(animate);

  return <>{isTransition ? value : count}</>;
}
