import { Close } from '@mui/icons-material';
import { IconButton, Zoom } from '@mui/material';
import {
  SnackbarAction,
  SnackbarOrigin,
  TransitionProps,
  closeSnackbar,
  enqueueSnackbar,
} from 'notistack';

// ----------------------------------------------------------------------

const DEFAULT_TRANSITION_COMPONENT = Zoom;
const DEFAULT_ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};
const DEFAULT_AUTO_HIDE_DURATION = 5000;

type Props_pop = {
  description: string;
  variant?: 'default' | 'error' | 'success' | 'info' | 'warning';
  TransitionComponent?:
    | React.JSXElementConstructor<
        TransitionProps & {
          children: React.ReactElement<any, any>;
        }
      >
    | undefined;
  anchorOrigin?: SnackbarOrigin | undefined;
  persist?: boolean;
  action?: SnackbarAction;
  autoHideDuration?: number;
};

type Props_popError = {
  errorMessage?: string;
};

export const actionClose: SnackbarAction = (snackbarId) => (
  <IconButton color="inherit" onClick={() => closeSnackbar(snackbarId)}>
    <Close fontSize="small" />
  </IconButton>
);

export function useSnackbar() {
  const snackbar = {
    pop: ({
      description,
      variant = 'default',
      TransitionComponent = DEFAULT_TRANSITION_COMPONENT,
      anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
      persist = false,
      action = null,
      autoHideDuration = DEFAULT_AUTO_HIDE_DURATION,
    }: Props_pop) =>
      enqueueSnackbar(description, {
        variant,
        TransitionComponent,
        anchorOrigin,
        action,
        persist,
        autoHideDuration,
      }),
    popError: ({ errorMessage }: Props_popError) =>
      enqueueSnackbar(errorMessage ? errorMessage : 'Une erreur est survenue', {
        variant: 'error',
        TransitionComponent: DEFAULT_TRANSITION_COMPONENT,
        anchorOrigin: DEFAULT_ANCHOR_ORIGIN,
        action: actionClose,
        persist: true,
      }),
  };

  return snackbar;
}
