import { Box, CircularProgress, LinearProgress } from '@mui/material';

type Props_LoadingBox_t = {
  type?: 'circular' | 'linear';
  size?: 'small' | 'medium' | 'large';
  width?: string;
  color?: string;
};

export default function LoadingBox({
  type = 'circular',
  size = 'medium',
  width,
  color,
}: Props_LoadingBox_t) {
  const sizeMap = {
    small: 20,
    medium: 30,
    large: 40,
  };

  const circularProps = {
    size: sizeMap[size],
  };

  const linearProps = {
    style: {
      width: width
        ? width
        : size === 'small'
          ? '40%'
          : size === 'medium'
            ? '75%'
            : '100%',
    },
  };

  const progresses = {
    circular: (
      <CircularProgress
        {...circularProps}
        sx={{
          color: (theme) => theme.palette.grey[500],
          ...(color && {
            color: color,
          }),
        }}
      />
    ),
    linear: (
      <LinearProgress
        {...linearProps}
        color="inherit"
        sx={{
          color: (theme) => theme.palette.grey[500],
          height: 3,
          borderRadius: 5,
          ...(color && {
            color: color,
          }),
        }}
      />
    ),
  };

  const ProgressComponent = progresses[type];

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {ProgressComponent}
    </Box>
  );
}
