import { PaletteMode } from '@mui/material';
import { grey } from '@mui/material/colors';
import { PaletteOptions, alpha } from '@mui/material/styles';
import { lighten } from 'polished';

// ----------------------------------------------------------------------

// SETUP COLORS

export const primary = {
  // lighter: '#D0ECFE',
  // light: '#73BAFB',
  main: '#7256FF',
  // dark: '#0C44AE',
  // darker: '#042174',
  // contrastText: '#FFFFFF',
};

export const secondary = {
  // lighter: '#EFD6FF',
  // light: '#C684FF',
  main: '#2051FF',
  // dark: '#5119B7',
  // darker: '#27097A',
  // contrastText: '#FFFFFF',
};

export const info = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

export const success = {
  lighter: '#7ad684',
  light: '#4ec85b',
  main: '#22bb33',
  dark: '#1b9528',
  darker: '#14701e',
  contrastText: '#FFFFFF',
};

export const warning = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: grey[800],
};

export const error = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

export const common = {
  black: '#000000',
  white: '#FFFFFF',
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: alpha(grey[500], 0.2),
  action,
};

// ----------------------------------------------------------------------
export function palette(mode: PaletteMode): PaletteOptions {
  return {
    mode: mode,
    ...base,
    text: {
      primary: mode === 'dark' ? grey[200] : grey[800],
      secondary: mode === 'dark' ? grey[400] : grey[600],
      disabled: mode === 'dark' ? grey[500] : grey[500],
    },
    background: {
      paper: mode === 'dark' ? lighten(0.03, '#0A0A0A') : '#FFFFFF',
      default: mode === 'dark' ? '#0A0A0A' : grey[100],
      neutral: mode === 'dark' ? grey[800] : grey[200],
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  };
}
