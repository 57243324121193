import { exit } from 'process';

// Difference between environment
export enum NodeEnvs {
  DEV = 'development',
  PROD = 'production',
}

type Env_t = {
  API_URL: string;
  APP_URL: string;
  STRIPE_PUBLIC_KEY: string;
};

type Envs_t = {
  [NodeEnvs.DEV]: Env_t;
  [NodeEnvs.PROD]: Env_t;
};

const envs: Envs_t = {
  [NodeEnvs.DEV]: {
    API_URL: 'http://localhost:3000',
    APP_URL: 'http://localhost:3001',
    STRIPE_PUBLIC_KEY:
      'pk_test_51OUV4uJhQYs3Fwt0qpOWe1BYU81J2ODymBWintNleNXEOigPo7gaK4q0lrEtSWgXZV1rc54DEYVz6ezqT0f95hWh00SzVsbILV',
  },
  [NodeEnvs.PROD]: {
    API_URL: 'https://www.trsform.com/api',
    APP_URL: 'https://www.trsform.com',
    STRIPE_PUBLIC_KEY:
      'pk_live_51OUV4uJhQYs3Fwt0TXowDUXGDgGzY7AKf2T1hd2626gomk01AxqespJx2ekB3qMQMTROS8dMzxluyqqo7Ko2488E00Oox2vrce',
  },
};

export const NODE_ENV: NodeEnvs = process.env.NODE_ENV as NodeEnvs;
const allowEnvs: NodeEnvs[] = [NodeEnvs.DEV, NodeEnvs.PROD];
if (!allowEnvs.includes(NODE_ENV)) {
  exit(1);
}

export const API_URL: string = envs[NODE_ENV].API_URL;
export const APP_URL: string = envs[NODE_ENV].APP_URL;
export const STRIPE_PUBLIC_KEY: string = envs[NODE_ENV].STRIPE_PUBLIC_KEY;

// Functions
export const isDevEnv = () => NODE_ENV === NodeEnvs.DEV;
export const isProdEnv = () => NODE_ENV === NodeEnvs.PROD;
