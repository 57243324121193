import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { Data_responseShare_t } from 'ts/types/notification.type';
import useNotification, { Props_NotificationItem } from '../use-notification';
import { CheckRounded, ClearRounded } from '@mui/icons-material';
import MatterText from './common/matter-text';
import PrimaryContainer from './common/primary-container';

export default function NotifResponseShare({
  notification,
}: Props_NotificationItem) {
  const data = notification.data as Data_responseShare_t;
  const theme = useTheme();
  const notificationHook = useNotification();

  // const handleClick = async () => {
  //   if (!notification.viewedOn) {
  //     const updatedNotifications: Notification_t[] =
  //       await useNotification.setNotificationViewed({
  //         notifications: notifications,
  //         notificationId: notification.notificationId,
  //       });
  //     setNotifications(updatedNotifications);
  //   }
  //   handleClose();
  //   router.push(`/gallery/my-trsforms`);
  //   router.push(
  //     `/gallery/trsform/${data.trsformId}/${data.emoji ? `${data.emoji} ${data.title}` : data.title}`,
  //   );
  // };

  return (
    <ListItem
      sx={{
        ...notificationHook.style.styleListItem({
          viewed: Boolean(notification.viewedOn),
        }),
      }}
      // onClick={handleClick}
    >
      <ListItemAvatar
        sx={{
          height: '100%',
          mt: 0.5,
        }}
      >
        {data.isAccepted ? (
          <CheckRounded color="success" fontSize="large" />
        ) : (
          <ClearRounded color="error" fontSize="large" />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          <PrimaryContainer createdAt={notification.createdAt}>
            <Typography variant="body2" color={theme.palette.grey[400]}>
              <MatterText
                text={
                  data.isAccepted
                    ? `${data.firstName} ${data.lastName}`
                    : data.email
                }
              />{' '}
              a {data.isAccepted ? 'accepté' : 'refusé'} votre invitation de
              partage à <MatterText text={data.title} />
            </Typography>
          </PrimaryContainer>
        }
      />
    </ListItem>
  );
}
