import React, { useContext } from 'react';
import { ContextUser } from '../context';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { Menu as MenuMui } from '@mui/material';
import Cookies from 'js-cookie';
import { lighten } from 'polished';

export default function Menu() {
  const { infoUser } = useContext(ContextUser);
  const { firstName, lastName, email, urlImageProfil } = infoUser;

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 1 }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar
          sx={{
            width: 44,
            height: 44,
            fontWeight: (theme) => theme.typography.fontWeightRegular,
            color: (theme) => theme.palette.grey[500],
            bgcolor: (theme) => theme.palette.grey[800],
          }}
          alt={`${firstName} ${lastName}`}
          src={urlImageProfil ? urlImageProfil : '/'}
        />
      </IconButton>
      <MenuMui
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          overflow: 'visible',
          fontSize: '0.87em',
          '& .MuiPaper-root': {
            mt: 0.5,
            width: '13%',
            background: (theme) =>
              lighten(0.1, theme.palette.background.default),
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box
          sx={{
            marginLeft: 3,
            marginRight: 3,
            marginTop: 1.5,
            marginBottom: 1.5,
          }}
        >
          <Typography
            sx={{
              fontSize: 'inherit',
              fontWeight: 600,
              color: (theme) => theme.palette.grey[300],
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {`${firstName} ${lastName}`}
          </Typography>
          <Typography
            sx={{
              fontSize: '0.9em',
              fontWeight: 500,
              color: (theme) => theme.palette.grey[600],
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {email}
          </Typography>
        </Box>
        <Divider
          sx={{
            borderStyle: 'dashed',
            mb: 1,
            mt: 1,
          }}
        />
        <MenuItem
          onClick={() => {
            navigate('/');
          }}
          sx={{
            fontSize: 'inherit',
            fontWeight: 600,
            color: (theme) => theme.palette.grey[300],
          }}
        >
          Home
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/user/profile');
            handleClose();
          }}
          sx={{
            fontSize: 'inherit',
            fontWeight: 600,
            color: (theme) => theme.palette.grey[300],
          }}
        >
          Mon profil
        </MenuItem>
        <Divider
          sx={{
            borderStyle: 'dashed',
          }}
        />
        <MenuItem
          onClick={() => {
            Cookies.remove('token');
            navigate('/');
          }}
          sx={{
            color: (theme) => theme.palette.error.main,
            fontSize: 'inherit',
            fontWeight: 600,
          }}
        >
          Se déconnecter
        </MenuItem>
      </MenuMui>
    </>
  );
}
